import dayjs from 'dayjs'

function formatActivityStream(date) {
  const dateObj = dayjs(date)
  const today = dayjs()
  const yesterday = today.subtract(1, 'day')

  if (dateObj.isSame(today, 'day')) {
    return "Today"
  }
  if (dateObj.isSame(yesterday, 'day')) {
    return "Yesterday"
  }
  return dateObj.format('MMMM D, YYYY')
}

export default formatActivityStream
