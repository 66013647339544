<template>
  <img
    v-if="currentCompanyFromUrl && currentCompany?.logoUrl"
    class="company-logo"
    :src="`${currentCompany.logoUrl}?t=${timestamp}`"
    :alt="currentCompany.name"
    @load="$emit('loaded')"
  >
</template>

<script>
import { mapState } from 'vuex'
import { defineComponent } from "vue"

export default defineComponent({
  name: 'CompanyLogo',

  emits: ['loaded'],

  data: () => ({
    unsubscribe: null,
    timestamp: new Date().getTime(),
  }),

  computed: {
    ...mapState('companies', ['currentCompany']),

    currentCompanyFromUrl() {
      return this.$route.params.company
    },
  },

  mounted() {
    this.unsubscribe = this.$store.subscribe((mutation) => {
      if (mutation.type === 'user/setConfigRecordTitle') {
        this.timestamp = new Date().getTime()
      }
    })
  },

  beforeUnmount() {
    this.unsubscribe()
  },
})
</script>

<style lang="scss">
  .company-logo {
    display: block;
    margin: 0 auto;
    max-height: 80px;
    max-width: 160px;
    height: auto;
    width: auto;
  }
</style>
