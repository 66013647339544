import { createApp, h } from "vue"
import { createPinia } from 'pinia'
import { createVfm } from 'vue-final-modal'
import Toast, { POSITION } from "vue-toastification"
import FloatingVue from 'floating-vue'
import 'floating-vue/dist/style.css'
import VueDragscroll from 'vue-dragscroll'
import 'vue-final-modal/style.css'

import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'

import App from './App.vue'
import router from './router'
import store from './store'
import viewAll from './directives/viewAll'

if (import.meta.env.VITE_APP_ENV === 'production') {
  const dynatraceScript = document.createElement('script')
  dynatraceScript.type = 'text/javascript'
  dynatraceScript.src = 'https://js-cdn.dynatrace.com/jstag/147f84b2bdc/bf40288snd/83e56aa3c9d4e6a2_complete.js'
  dynatraceScript.crossOrigin = 'anonymous'
  document.head.appendChild(dynatraceScript)
}

dayjs.extend(customParseFormat)
dayjs.extend(utc)
dayjs.extend(timezone)

const app = createApp({
  name: 'VsimpleApp',
  render: () => h(App),
})

const pinia = createPinia()

app.use(pinia)
app.use(router)
app.use(store)

app.use(Toast, {
  position: POSITION.TOP_RIGHT,
  duration: 3000,
})
const vfm = createVfm()
app.use(vfm)

app.use(FloatingVue)
app.use(VueDragscroll)
app.directive('viewAll', viewAll)

const init = async () => {
  const pdfjs = await import('pdfjs-dist/legacy/build/pdf')
  const pdfjsWorker = await import('pdfjs-dist/legacy/build/pdf.worker.js?url')


  pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker.default
  app.mount('#app')
}

init().then(() => {
  if (import.meta.env.VITE_APP_ENV !== 'production') {
    console.info('App mounted')
  }
})
