import { RESPONSE_ERRORS } from "@/constants/RESPONSE_ERRORS"
import { useToast } from "vue-toastification"

export const apiErrorHandler = (apiError) => {
  if (apiError.config?.headers?.notification === 'silent' || apiError.config.ignoreGlobalNotify) {
    return
  }
  const $toast = useToast()
  const toastType = apiError?.response?.data?.notificationType || 'error'

  const promisedError = new Promise((resolve, reject) => {
    if (apiError?.code === "ERR_NETWORK") {
      reject(new Error(RESPONSE_ERRORS.network))
    }

    const error = apiError?.response || {}

    if (error.status === 422) {
      const errorMessage = error?.data?.message
      const mappedError = Object.values(error?.data?.errors || {})
        .map((errors) => {
          if (errors instanceof Array) {
            return errors[0]
          }

          return String(errors)
        })
        .reduce((prev, next) => {
          if (prev === next) {
            return prev
          }
          return [prev, next].filter(Boolean).join('\n')
        }, '')

      reject(new Error(String(errorMessage || mappedError)))
    }

    if (RESPONSE_ERRORS[error.status]) {
      reject(new Error(RESPONSE_ERRORS[error.status]))
    }

    reject(error?.data?.message ? new Error(error?.data?.message) : new Error(RESPONSE_ERRORS.default))
  })

  promisedError.catch((err) => {
    if (err.message === RESPONSE_ERRORS.network) {
      $toast.clear()
    }

    $toast(err.message, {
      type: toastType,
    })
  })
}
