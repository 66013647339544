import dayjs from "dayjs"
const auth = ({
  to,
  next,
}) => {
  const company = to?.params?.company
  const tokenExp = localStorage.getItem('tokenExp')
  if (tokenExp && dayjs(tokenExp).isBefore(dayjs())) {
    localStorage.removeItem('token')
    localStorage.removeItem('tokenExp')
    localStorage.removeItem('supplier_company')
    const loginPath = company ? { name: 'company-login', params: { company } } : { name: 'login' }
    return next(loginPath)
  }
  const isAuth = localStorage.getItem('token')
  const isGod = localStorage.getItem('currentCompanySlug') === 'vsimple-integrations'
  if (isAuth && isGod) {
    if (company === 'vsimple-integrations') {
      return next()
    }
    return next({
      name: 'god-user',
      params: {
        company: 'vsimple-integrations',
      },
    })
  }
  if (!isAuth) {
    localStorage.setItem('userRequestUrl', JSON.stringify({ name: to.name, query: to.query, params: to.params }))
    const loginPath = company ? { name: 'company-login', params: { company } } : { name: 'login' }
    return next(loginPath)
  }
  const userRequestUrl = localStorage.getItem('userRequestUrl')
  if (isAuth && userRequestUrl) {
    const userRequest = JSON.parse(userRequestUrl)
    const requestCompany = userRequest.params.company
    localStorage.removeItem('userRequestUrl')
    if (requestCompany === company) {
      next(userRequest)
    }
  }

  return next()
}

export default auth
