import { companies, records } from '@/api'

export default {
  getUserCompanies: ({ commit }, account) => {
    commit('preloader', true)
    return companies.getUserCompanies(account)
      .then(({ data }) => {
        commit('setCompanies', data.data)
        return data.data
      }).catch((err) => {
        commit('preloader', false)
        throw err
      })
      .finally(() => {
        commit('preloader', false)
      })
  },

  getCurrentCompany: ({ commit }, id) => {
    return companies.getCompanyPublic(id)
      .then(({ data }) => {
        commit('setCurrentCompany', data.data)
      })
      .catch((e) => {
        throw e
      })
  },

  getRecords({ commit }, filters) {
    return records.getRecords(filters)
      .then(({ data }) => {
        commit('setPreOrders', data.data)
      })
  },
}
