import admin from '@/api/admin/index'
import IMPORT_TYPES from '@/constants/enums/IMPORT_TYPES'

export default {
  getAdminSectionsData({ commit }) {
    return Promise.all([
      admin.getAdminSectionsList(),
      admin.getAdminCustomFieldsConfiguration(),
    ]).then(([sectionsList, customFieldsList]) => {
      commit('setSectionList', sectionsList.data.data)
      commit('setCustomFields', customFieldsList.data.data)
    })
  },
  createSection({ commit, getters }, name) {
    return admin.createSection({
      name,
      position: getters.sectionsAggregation.length,
    }).then(({ data }) => {
      commit('addSectionToList', data.data)
      return data.data
    })
  },
  updateSection({ commit }, payload) {
    commit('updateSectionFromList', payload)
    return admin.updateSection(payload).then(({ data }) => {
      commit('updateSectionFromList', data.data)
    })
  },
  reorderSections({ commit }, sectionsAggregation) {
    const sectionsWithNewPosition = sectionsAggregation.map((sectionAggregation, index) => ({
      ...sectionAggregation.section,
      position: index,
    }))

    commit('setSectionList', sectionsWithNewPosition)

    return admin.reorderSections(sectionsWithNewPosition.map(({ position, slug }) => ({
      position,
      slug,
    })))
  },
  reorderCustomFields({ commit }, customFields) {
    const customFieldsWithNewPosition = customFields.map((customField, index) => ({
      slug: customField.slug,
      position: index + 1,
    }))

    commit('reorderCustomFields', customFieldsWithNewPosition)

    return admin.updateCustomFieldsPosition({ data: customFieldsWithNewPosition })
  },
  reorderObjectFields({ commit }, { slug, fields }) {
    const mappedObjectFields = fields.map((field, index) => ({
      name: field.name,
      position: index,
    }))

    commit('reorderObjectCustomField', {
      slug,
      fields: mappedObjectFields,
    })

    return admin.updateCustomFieldsObjectChildPosition(slug, {
      data: mappedObjectFields,
    })
  },
  createCustomField({ commit, state }, field) {
    return admin.createAdminSectionField(field).then(({ data: { data } }) => {
      commit('addCustomField', data)
      commit('records/setCustomFieldsConfiguration', state.customFields, { root: true })
    })
  },
  updateCustomField({ commit, state }, field) {
    commit('updateCustomField', field)
    commit('records/setCustomFieldsConfiguration', state.customFields, { root: true })
    return admin.updateAdminSectionField(field).then(({ data: { data } }) => {
      commit('updateCustomField', data)
      commit('records/setCustomFieldsConfiguration', state.customFields, { root: true })
    })
  },
  importFile(ctx, payload) {
    const file = new FormData()
    file.append('file', payload.file)
    if (payload.slug) {
      file.append('quoteCustomFieldSlug', payload.slug)
    }
    switch (payload.type) {
      case IMPORT_TYPES.insert:
        return payload.slug ? admin.importChildRecordInsert(file) : admin.importRecordInsert(file)
      case IMPORT_TYPES.update:
        return payload.slug ? admin.importChildRecordUpdate(file) : admin.importRecordUpdate(file)
      case IMPORT_TYPES.upsert:
        return payload.slug ? admin.importChildRecordUpsert(file) : admin.importRecordUpsert(file)
      default:
        return null
    }
  },
  downloadCsvTemplate(ctx, payload) {
    const data = {
      importerType: payload.type,
    }
    if (payload.slug) {
      data.quoteCustomFieldSlug = payload.slug
      return admin.importChildRecordTemplate(data)
    }
    return admin.importRecordTemplate(data)
  },
  requestFunctionConfigurations: ({ commit }) => {
    return admin.loadFunctionsConfigurations().then(({ data: { data: functionsConfigurations } }) => {
      commit('setFunctionsConfigurations', functionsConfigurations)
    })
  },
  getEntityConditions({ commit }) {
    return admin.getEntityConditions().then(({ data: { data }}) => {
      commit('setEntitiesCondition', data)
    })
  },
  createEntityConditions({ commit }, payload) {
    return admin.createEntityConditions(payload).then(({ data: { data } }) => {
      commit('addEntityConditions', data)
    })
  },
  updateEntityConditions({ commit }, payload) {
    return admin.updateEntityConditions(payload.id, payload).then(({ data: { data } }) => {
      commit('updateEntityConditions', data)
    })
  },
  deleteEntityConditions({ commit }, id) {
    return admin.deleteEntityConditions(id).then(() => {
      commit('deleteEntityConditions', id)
    })
  },
}
