import defaultState from './defaultState'

export default {
  toggleSidebar(state) {
    state.isSidebarShown = !state.isSidebarShown
  },
  setMainTitle(state, title) {
    state.mainTitle = title
  },
  setRefreshTokenModalClosed(state, payload) {
    state.isRefreshTokenModalClosed = payload
  },
  reset(state) {
    Object.assign(state, defaultState())
  },
}
