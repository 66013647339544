<template>
  <div :class="styles['auth-layout']">
    <div :class="styles['auth-layout__banner-wrapper']">
      <div :class="styles['auth-layout__banner']">
        <h3 :class="styles['auth-layout__banner-title']">
          Sign & share documents
          <br>
          right inside your workflows.
        </h3>
        <p :class="styles['auth-layout__banner-description']">
          Backed by signNow, vSign lets you fully execute
          documents without leaving the platform.
        </p>
        <AuthBannerSvg :class="styles['auth-layout__banner-svg']" />
      </div>
      <AuthLayoutSvg :class="styles['auth-layout__banner-bg']" />
    </div>
    <div :class="styles['auth-layout__content-wrapper']">
      <div
        :class="[
          styles['auth-layout__logo-wrapper'],
          {
            [styles['auth-layout__logo-wrapper--top']]: isLogoTop
          }
        ]"
      >
        <CompanyLogo
          v-if="hasCompany"
        />
        <VSimpleLogo
          v-else
        />
      </div>
      <router-view />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, provide, useCssModule } from "vue"
import CompanyLogo from '@/components/features/CompanyLogo.vue'
import VSimpleLogo from '@/assets/images/vsimple-auth-logo.svg'
import { useRoute } from "vue-router"
import AuthLayoutSvg from '@/assets/images/auth-layout.svg'
import AuthBannerSvg from '@/assets/images/auth-banner.svg'

const route = useRoute()

const hasCompany = computed(() => {
  const { company } = route.params

  return company && !['undefined', 'null'].includes(company as string)
})

const isLogoTop = computed(() => {
  return [
    'create-account',
    'company-create-account',
  ].includes(route.name as string)
})

provide('hasCompany', hasCompany.value)

const styles = useCssModule()
</script>

<style lang="scss" module>
.auth-layout {
  display: grid;
  grid-template-columns: 1fr 1fr;
  height: 100%;
  background-color: var(--v-app-bg);

  @include media-breakpoint-down(md) {
    grid-template-columns: 1fr;
  }
}

.auth-layout__banner-wrapper {
  position: relative;
  background: linear-gradient(141deg, #E2AEFF 27.47%, #99FEE0 87.56%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
  @include media-breakpoint-down(md) {
    display: none;
  }
}

.auth-layout__banner-bg {
  position: absolute;
  right: 0;
  bottom: 0;
  max-width: 100%;
  height: 54%;
  width: auto;
  z-index: 0;
}

.auth-layout__content-wrapper {
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  filter: var(--v-primary-shadow);
  @include media-breakpoint-down(sm) {
    padding: 10px;
  }
}
.auth-layout__logo-wrapper {
  position: absolute;
  max-width: 250px;
  width: 100%;
  height: auto;
  left: 50%;
  top: 40px;
  transform: translateX(-50%);

  svg {
    width: 100%;
    height: auto;
  }

  @include media-breakpoint-down(sm) {
    top: 20px;
    max-width: 200px;
  }
}
.auth-layout__logo-wrapper--top {
  top: 20px;
}

.auth-layout__banner-title {
  font-size: 36px;
  font-family: var(--v-font-primary);
  text-align: center;
  margin-bottom: 48px;
  color: #191347;
  font-weight: 600;
}

.auth-layout__banner-description {
  font-size: 16px;
  font-family: var(--v-font-primary);
  color: #191347;
  margin: 0 auto 48px;
  max-width: 400px;
  text-align: center;
}

.auth-layout__banner-svg {
  align-self: flex-end;
  max-width: 100%;
  transform: translateX(20px);
}

.auth-layout__banner {
  z-index: 1;
  display: flex;
  flex-direction: column;
  max-width: 544px;
  width: 100%;
  margin: 0 auto;
}
</style>
