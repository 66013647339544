import qs from 'qs'

export default {
  toObject(obj) {
    const result = {}

    Object.entries(obj).forEach(([key, value]) => {
      const splittedKey = key.split(/\[|\]/).filter(Boolean)
      if (splittedKey.length > 1) {
        return splittedKey.reduce((acc, keyPart, currentIndex) => {
          if (currentIndex >= splittedKey.length - 1) {
            acc[keyPart] = value
          }
          if (!Reflect.has(acc, keyPart)) {
            acc[keyPart] = {}
          }
          return acc[keyPart]
        }, result)
      }
      result[key] = value
    })

    return result
  },
  toObjectFromQs(string) {
    return qs.parse(string)
  },
  toQueryString(obj) {
    return qs.stringify(obj)
  },
}
