import httpClient from "@/httpClient"
import dayjs from "dayjs"
import formatForApi from "@/js/filters/formatForApi"

const getCurrentUser = (company) => httpClient.get(`${company ? company : '{currentCompanySlug}'}/user/profile`)
const saveUserAvatar = (file) => httpClient({
  method: 'POST',
  url: '{currentCompanySlug}/user/profile/avatar',
  data: file,
  headers: { 'Content-Type': 'multipart/form-data' },
})

const changeUserStatus = (payload) => {
  return httpClient.post('/user/profile/status', payload ? {
    name: payload.name,
    expiresAt: formatForApi(payload.expiresAt),
  } : {
    name: null,
  })
}
const getUserTypes = () => httpClient.get(`{currentCompanySlug}/company-accounts/types`)
const getUserPermissions = () => httpClient.get('/permissions')
const getUsers = ({ params = {} } = {}) => {
  let perPage = 10
  if (params.userIds) {
    const userIdsLength = params.userIds.split('|').length
    perPage = perPage < userIdsLength ? userIdsLength : perPage
  }

  return httpClient.get(`{currentCompanySlug}/company-accounts`, {
    params: {
      ...params,
      per_page: perPage,
    },
  })
}
const getUser = ({ id }) => httpClient.get(`{currentCompanySlug}/company-accounts/${id}`)
const createUser = ({ payload }) => httpClient.post(`{currentCompanySlug}/company-accounts`, payload)
const editUser = ({ id, payload }) => httpClient.patch(`{currentCompanySlug}/company-accounts/${id}`, payload)
const activateUser = (id) => httpClient.patch(`{currentCompanySlug}/users/suppliers/${id}/activate`)
const deactivateUser = (id) => httpClient.patch(`{currentCompanySlug}/users/suppliers/${id}/deactivate`)
const resetPassword = (id) => httpClient.patch(`{currentCompanySlug}/users/suppliers/${id}/reset-password`)
const getAccountsRecords = ({ id }) => httpClient.get(`{currentCompanySlug}/accounts/${id}/records`)
const removeAccountsRecords = ({ id, recordIds = []}) =>
  httpClient.post(`{currentCompanySlug}/accounts/${id}/records/remove`, {recordIds: recordIds})
const addAccountsRecords = ({ id, recordIds = []}) =>
  httpClient.post(`{currentCompanySlug}/accounts/${id}/records/add`, {recordIds: recordIds})


export default {
  getCurrentUser,
  getUserTypes,
  getUserPermissions,
  saveUserAvatar,
  changeUserStatus,
  getUsers,
  getUser,
  createUser,
  editUser,
  activateUser,
  deactivateUser,
  resetPassword,
  getAccountsRecords,
  removeAccountsRecords,
  addAccountsRecords,
}
