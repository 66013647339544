import defaultState from './defaultState'

const RESERVED_FILTER_VIEWS_TITLES = Object.freeze({
  default: 'Default',
})

export default {
  preloader(state, isLoading) {
    state.isLoading = isLoading
  },
  preloaderMore(state, isLoadingMore) {
    state.isLoadingMore = isLoadingMore
  },
  preloaderExport(state, isExportLoading) {
    state.isExportLoading = isExportLoading
  },
  preloaderSaving(state, isPreorderSaving) {
    state.isSaving = isPreorderSaving
  },
  preloaderCollaborators(state, isRecordCollaborators) {
    state.isRecordCollaborators = isRecordCollaborators
  },
  setRecords(state, records) {
    state.records = records
  },
  setRecordsRedDotsZeroState(state) {
    state.records = state.records.map((record) => ({ ...record, notificationsCount: 0 }))
  },
  setStatusGroups(state, statusGroups) {
    state.statusGroups = statusGroups.reverse()
  },
  setRecord(state, record) {
    state.record = {...record, collaborators: state.record.collaborators }
  },
  setRecordMeta(state, meta) {
    state.recordMeta = meta
  },
  setStatuses(state, statuses) {
    state.statuses = statuses
      .filter((status) => !status.deletedAt)
      .sort((a, b) => a.sortOrder - b.sortOrder)
    state.statusesWithDeleted = statuses
  },
  setPagination(state, meta) {
    state.pagination = meta
  },
  setCurrentPage(state, page) {
    state.pagination.current_page = page
  },
  setPerPage(state, perPage) {
    state.pagination.per_page = perPage
  },
  setFilter(state, data) {
    state.filterList = data
  },
  setActivityStream(state, activityStream) {
    state.activityStream = activityStream
  },
  activityStreamLoader(state, isActivityStreamLoading) {
    state.isActivityStreamLoading = isActivityStreamLoading
  },
  messageSending(state, isMessageSending) {
    state.isMessageSending = isMessageSending
  },
  messageUpdating(state, isMessageUpdating) {
    state.isMessageUpdating = isMessageUpdating
  },
  issueCreating(state, isIssueCreating) {
    state.isIssueCreating = isIssueCreating
  },
  setRecordFiles(state, files) {
    state.recordFiles = files
  },

  deleteRecordFile(state, id) {
    state.recordFiles = state.recordFiles.filter((file) => file.id !== id)
  },

  setFilesRelatedUsers(state, meta) {
    state.filesRelatedUsers = meta?.users.map((user) => {
      return {
        ...user.account,
        ...user.companyAccount,
        id: user.account.id,
        accountId: user.companyAccount.id,
        tags: user.tags,
      }
    }) || []
  },
  setRecordFileGroups(state, groups) {
    state.recordFileGroups = groups
  },
  resetRecordData(state) {
    state.record = {
      fulfillments: [],
      items: [],
      collaborators: [],
      customFields: [],
    }
  },
  addFilters(state, filters) {
    state.filters = filters
  },
  addStatus(state, status) {
    state.statuses.push(status)
  },
  removeStatus(state, statusSlug) {
    const index = state.statuses.findIndex((status) => status.slug === statusSlug)
    if (index !== -1) {
      state.statuses.splice(index, 1)
    }
  },
  addAdvancedOptions(state, advancedOptions) {
    state.advancedOptions = Object.entries(advancedOptions).map(([key, value]) => ({ label: value, option: key }))
  },
  addUserSettingFilters(state, filtersSettings) {
    state.filtersSettings = filtersSettings
  },
  updateRecordItem(state, { id, data, isSingle }) {
    if (isSingle) {
      const record =  data.data ? data.data : data
      state.record = {...record, collaborators: state.record.collaborators }
    } else {
      state.records = state.records.map((record) => {
        if (state.records.length === 0) {
          state.record = data
        }

        if (record.id === id) {
          return data
        }

        return record
      })
    }
  },
  setRecordsUsers(state, payload) {
    state.recordsUsers = payload
  },
  setRecordUsers(state, payload) {
    state.recordUsers = payload
  },
  errorUpdateRecord(state, error) {
    if (Object.keys(error).length) {
      state.errorUpdateRecord = { id: error.id, error: error.error }
    }
  },
  clearUpdateRecordErrorById(state, id = null) {
    if (state.errorUpdateRecord?.id === id) {
      state.errorUpdateRecord = {}
    }
  },
  addNewObjectFieldChild(state, payload) {
    state.record.customFields[payload.recordKey]
      .find((field) => field.id === payload.objectId)
      .customFieldObjects.push(payload.data.data)
  },
  setDefaultActivityStream(state) {
    state.isActivityStreamLoading = true
    state.recordFiles = []
    state.record = {
      fulfillments: [],
      items: [],
      collaborators: [],
      customFields: [],
    }
  },
  setDefaultRecord(state) {
    state.record = {
      fulfillments: [],
      items: [],
      collaborators: [],
      customFields: [],
    }
  },
  setDefaultRecordFiles(state) {
    state.recordFiles = []
  },
  setActiveFilters(state, payload) {
    state.activeFilters = payload
  },
  setActiveFiltersValue(state, payload) {
    state.activeFiltersValue = payload
  },
  setPersistentColumns(state, { data }) {
    state.persistentColumns = data.columns
  },
  reset(state) {
    Object.assign(state, defaultState())
  },
  setTemplateDocumentsList(state, list) {
    state.templateDocumentsList = list.filter((templateDocument) => !templateDocument.deletedDate)
    state.templateDocumentsListWithDeleted = list
  },
  setSelectedFileTypes(state, types) {
    state.selectedFileTypes = types
  },
  setCustomFieldsConfiguration(state, payload) {
    state.customFieldsConfiguration = payload
  },
  setRecordsFilters(state, payload) {
    state.recordsFilters = payload
  },
  setViewFilters(state, payload) {
    state.viewFilters = payload
  },
  setViewFiltersId(state, payload) {
    state.viewFiltersId = payload
  },
  setFilterViewsLoaded(state, payload) {
    state.isFilterViewsLoaded = payload
  },
  setFilterViews(state, payload) {
    state.filterViews = payload.map((view) => {
      const mappedFilters = Object.entries(view.filters).filter(([, value]) => {
        return typeof value !== 'string' && value
      }).reduce((acc, [key, value]) => {
        acc[key] = {
          ...value,
          value: value?.value instanceof Array ? value.value.join('|') : value.value,
        }
        return acc
      }, {})

      return {
        ...view,
        filters: {
          ...view.filters,
          ...mappedFilters,
        },
      }
    }).sort((a, b) => (a.order === null ? 1 : a.order) - (b.order === null ? 1 : b.order))
  },
  updateFiltersViews(state, payload) {
    state.filterViews = payload
  },
  updateFilterView(state, payload) {
    const index = state.filterViews.findIndex(({ id }) => id === payload.id)
    if (index === -1) {
      return
    }
    state.filterViews.splice(index, 1, {
      ...state.filterViews[index],
      ...payload,
    })
  },
  setActivityStreamPage(state, meta) {
    state.activityStreamPage = meta
  },
  setActivityFirstPage(state) {
    state.activityStreamPage.currentPage = 1
  },
  setCollaborators(state, payload) {
    state.record.collaborators = payload
  },
  setSections(state, payload) {
    state.sections = payload
  },
  setSort(state, payload) {
    state.sort = payload
  },
  setActiveView(state, payload) {
    state.activeView = payload
  },
  updateHasSummary(state, payload) {
    const recordFile = state.recordFiles.find((file) => file.id === payload)
    if (!recordFile) {
      return
    }
    recordFile.summaryMeta.hasSummary = true
  },
}
