export default function defaultState() {
  return {
    companies: [],
    projects: [],
    transactions: [],
    preOrders: [],
    currentCompany: null,
    isLoading: false,
  }
}
