import defaultState from './defaultState'

export default {
  setPattern(state, payload) {
    state.pattern = payload
  },
  reset(state) {
    Object.assign(state, defaultState())
  },
}
