import httpClient from "@/httpClient"
import { ApiResponse, ApiResponseWithMeta } from "~/types/common"
import {
  NotificationGroupItem,
  NotificationItemExtended,
  NotificationsGroupListingParams,
  NotificationsListingMeta,
  NotificationsListingParams,
} from "~/types/notifications"
import qs from 'qs'

export const loadNotifications = (
  params: NotificationsListingParams = {},
): ApiResponseWithMeta<NotificationItemExtended[], NotificationsListingMeta> => {
  return httpClient.get('/notifications', {
    params: Reflect.has(params, 'read') ? {
      ...params,
      limit: 10,
      read: Number(params.read),
    } : {
      ...params,
      limit: 10,
    },
  })
}

export const loadNotificationsGroups = (
  params: NotificationsGroupListingParams = {},
): ApiResponseWithMeta<NotificationGroupItem[], NotificationsListingMeta> => {
  return httpClient.get('/records/notifications', {
    params: Reflect.has(params, 'read') ? {
      ...params,
      read: Number(params.read),
      limit: 5,
      offset: params.offset || 0,
      notificationsLimit: 10,
    } : {
      ...params,
      limit: 5,
      offset: params.offset || 0,
      notificationsLimit: 10,
    },
  })
}

export const loadNotificationByRecords = (
  recordIds: string,
): ApiResponse<Record<number, number>> => {
  return httpClient.get('/notifications/counts', {
    params: {
      recordIds,
    },
  }).then(({ data }) => {
    return data.reduce((acc, item) => {
      acc[item.recordId] = item.count
      return acc
    }, {})
  })
}

export const loadNotificationsCategories = () => {
  return httpClient.get('/notifications/categories')
}

export const changeNotificationCategory = (slug: string, payload: unknown) => {
  return httpClient.patch(`/notifications/categories/${slug}/settings`, payload)
}

export const markNotificationRead = (id: number) => {
  return httpClient.patch(`/notifications/${id}`, { read: true })
}

export const markNotificationUnread = (id: number) => {
  return httpClient.patch(`/notifications/${id}`, { read: false })
}

export const deleteNotification = (id: number) => {
  return httpClient.delete(`/notifications/${id}`)
}

export const markNotificationsRead = (recordId?: number, categoryIds?: number[]) => {
  const query = qs.stringify({
    ...(recordId ? {recordIds: recordId} : {}),
    ...(categoryIds?.length ? { categoryIds: categoryIds.join('|') } : {}),
  })
  return httpClient.patch(`/notifications/read${query ? `?${query}` : ''}`)
}
