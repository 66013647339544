import httpClient from "@/httpClient"

const login = (payload) => httpClient.post('/auth/login', payload)
const forgotPassword = (payload) => httpClient.post('/auth/password-forgot', payload, { ignoreGlobalAuthTokenSet: true })
const resetPassword = (payload) => httpClient.post('/auth/password-reset', payload)
const setCompany = (companySlug) => httpClient.post('/auth/set-company', { company: companySlug })
const createAccount = (payload) => httpClient.patch('/auth/update-invited-user', payload)
const getAccountData = (params) => httpClient.get('/user/get-invited-user-profile', { params })
const refreshToken = (payload = {}) => httpClient.patch('/auth/refresh-token', payload)

export default {
  login,
  forgotPassword,
  resetPassword,
  setCompany,
  createAccount,
  getAccountData,
  refreshToken,
}
