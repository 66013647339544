export default function defaultState() {
  return {
    users: [],
    pagination: null,
    singleUser: null,
    isUsersLoading: true,
    noPermissions: false,
    noCollaborators: [],
    searchUsers: [],
  }
}
