const DEFAULT_RESPONSE_ERROR = 'Something bad happened, please try again later or contact Support'

export const RESPONSE_ERRORS = Object.freeze({
  504: 'This service is temporary unavailable. Please try again later',
  408: 'Timeout exceeded, please re-check your internet connection and try again later',
  500: DEFAULT_RESPONSE_ERROR,
  409: 'Such item already exists',
  404: 'Such item not found',
  400: 'The request you sent, seems to be invalid',
  403: 'Request forbidden',
  401: 'Access denied',
  network: 'Network error, please re-check your internet connection and try again',
  default: DEFAULT_RESPONSE_ERROR,
})
