import defaultState from './defaultState'

export default {
  preloader(state, isLoading) {
    state.isLoading = isLoading
  },

  preloaderExport(state, isExportLoading) {
    state.isExportLoading = isExportLoading
  },

  setTags(state, payload) {
    state.tags = [payload]
  },

  setTag(state, payload) {
    state.tags[0].push(payload)
  },

  setTagItem(state, data) {
    state.tagsUsers[data.type] = [...(state.tagsUsers[data.type] || []), data.value]
  },

  updateTagsItem(state, data) {
    if (data.index !== undefined) {
      data.value.splice(data.index, 1)
    }
    state.tagsUsers[data.type] = data.value
  },

  reset(state) {
    Object.assign(state, defaultState())
  },

  removeTag(state, tagId) {
    const index = state.tags[0].findIndex((tag) => tag.id === tagId)
    if (index === -1) {
      return
    }
    state.tags[0].splice(index, 1)
  },

  renameTag(state, data) {
    const tag = state.tags[0].find((tag) => tag.id === data.id)
    if (!tag) {
      return
    }
    tag.name = data.name
  },
}
