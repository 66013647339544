export default function defaultState() {
  return {
    isLoading: true,
    isExportLoading: false,
    isMessageSending: false,
    isActivityStreamLoading: false,
    isIssueCreating: false,
    tags: [],
    tagsUsers: {},
  }
}
