export default function defaultState() {
  return {
    isLoaded: false,
    currentUser: null,
    roles: [],
    statuses: [],
    permissions: {},
    isConfigLoaded: false,
    config: {
      orders: {
        display: false,
      },
      displayESign: {
        display: false,
      },
      preorders: {
        display: false,
        title: {
          custom: {
            plural: '',
            singular: '',
          },
          default: {
            plural: 'Pre-Orders',
            singular: 'Pre-Record',
          },
          helpField: [],
        },
      },
      displayRecordChangeLog: {
        display: false,
      },
      csv_export: {
        display: false,
      },
      recordTypes: {
        display: false,
      },
      recordFormulas: {
        display: false,
        items: [],
      },
      displayVforms: {
        display: false,
      },
    },
    role: '',
    type: '',
    recordAccess: '',
    financialAccess: '',
  }
}
