const CUSTOM_FIELDS_TYPES = Object.freeze({
  object: 'object',
  text: 'text',
  dropdown: 'dropdown',
  select: 'select',
  date: 'date',
  datetime: 'datetime',
  day: 'day',
  number: 'number',
  currency: 'currency',
  percent: 'percent',
  dropdownMultiple: 'dropdown-multiple',
  user: 'user',
  condition: 'condition',
  email: 'email',
  link: 'link',
  account: 'account',
  task: 'task',
  textLong: 'text-long',
  multiSelect: 'multi-select',
})

export default CUSTOM_FIELDS_TYPES
