import defaultState from './defaultState'

export default {
  setFileGroups(state, fileGroups) {
    state.fileGroups = fileGroups.sort((a, b) => a.sortOrder - b.sortOrder)
      .map((item) => ({ ...item, isDefaultType: item.default }))
  },
  updateFileGroup(state, payload) {
    const index = state.fileGroups.findIndex((item) => item.id === payload.id)
    const oldFileGroups = [...state.fileGroups]
    oldFileGroups[index] = payload
    state.fileGroups = oldFileGroups
  },
  addFileGroup(state, payload) {
    state.fileGroups.push(payload)
  },
  reset(state) {
    Object.assign(state, defaultState())
  },
  preloader(state, isLoading) {
    state.isLoading = isLoading
  },
}
