import defaultState from './defaultState'

export default {
  setUsers(state, users) {
    state.users = users.data.map((user) => ({
        ...user.account,
        ...user.companyAccount,
        id: user.account.id,
        accountId: user.companyAccount.id,
        tags: user.tags,
    }))
    state.pagination = users.meta
    state.isUsersLoading = false
  },
  setSingleUser(state, user) {
    state.singleUser = {
      ...user.account,
      ...user.companyAccount,
      id: user.account.id,
      accountId: user.companyAccount.id,
      tags: user.tags,
    }
  },
  loading(state, isUsersLoading) {
    state.isUsersLoading = isUsersLoading
  },

  clearUsers(state) {
    state.users = []
  },

  updateOneUserFromMany(state, user) {
    const { users } = state
    const userIndex = users.findIndex((userItem) => userItem.id === user.id)
    const updatedUser = { ...users[userIndex], status: user.status }

    state.users = [
      ...users.slice(0, userIndex),
      updatedUser,
      ...users.slice(userIndex + 1),
    ]
  },

  setNoPermissions(state, noPermissions) {
    state.noPermissions = noPermissions
  },

  setNoCollaborators(state, users) {
    state.noCollaborators = users
  },

  setSearchUsers(state, payload) {
    state.searchUsers.push(payload)
  },

  reset(state) {
    Object.assign(state, defaultState())
  },
}
