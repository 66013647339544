import httpClient from "@/httpClient"

export const loadRecordTypes = (companySlug = "") => httpClient.get(`/${companySlug || '{currentCompanySlug}'}/supplierCompany/applicationConfiguration/record-types`)

const changeRecordTypesActive = (payload) => httpClient.patch(
  '/{currentCompanySlug}/features',
  { displayRecordTypes: payload },
)

const createRecordType = (form) => httpClient.post('/{currentCompanySlug}/supplierCompany/applicationConfiguration/record-types', form)

const updateRecordType = (slug, data) => httpClient.patch(
  `/{currentCompanySlug}/supplierCompany/applicationConfiguration/record-types/${slug}`,
  data,
)

const deleteRecordTypeEvent = (recordTypeSlug, params) => httpClient.delete(
  `/{currentCompanySlug}/supplierCompany/applicationConfiguration/record-types/${recordTypeSlug}`,
  {
    params,
    headers: {
      notification: 'silent',
    },
  },
)

const reorderRecordType = (payload) => httpClient.post(
  '/{currentCompanySlug}/supplierCompany/applicationConfiguration/record-types/reorder',
  payload)

export default {
  loadRecordTypes,
  createRecordType,
  updateRecordType,
  deleteRecordTypeEvent,
  changeRecordTypesActive,
  reorderRecordType,
}
