import httpClient from "@/httpClient"
import AwesomeDebouncePromise from "awesome-debounce-promise"

const getUsers = ({ params }, forceCompany = '') => {
  return httpClient.get(`${forceCompany || '{currentCompanySlug}'}/company-accounts`,
    {
      params,
    })
}

let mentionUsersAbort = new AbortController()

const getMentionable = (recordId, { params }) => {
  mentionUsersAbort.abort()
  mentionUsersAbort = new AbortController()
  return httpClient.get(`{currentCompanySlug}/quotes/${recordId}/mentionable`,
    {
      params,
      signal: mentionUsersAbort.signal,
    })
}

export default {
  getUsers: getUsers,
  debouncedGetMentionable: AwesomeDebouncePromise(getMentionable, 1000),
}
