import defaultState from './defaultState'

export default {
  setCurrentUser(state, user) {
    state.currentUser = {
      ...user.account,
      ...(user.companyAccount || {}),
      id: user.companyAccount?.id || null,
      accountId: user.account.id,
      tags: user.tags,
      outlookIntegration: user.outlookIntegration,
    }
  },
  updateCurrentUser(state, user) {
    if (!state.currentUser) {
      return
    }

    state.currentUser = {
      ...state.currentUser,
      ...user,
    }
  },
  setCurrentUserAvatar(state, link) {
    state.currentUser.avatar = link
  },
  setCurrentUserStatus(state, link) {
    state.currentUser.status = link
  },
  setRoles(state, roles) {
    state.roles = roles
  },
  setStatuses(state, statuses) {
    state.statuses = statuses
  },
  setConfig(state, config) {
    state.config = config
  },
  setIsConfigLoaded(state, payload) {
    state.isConfigLoaded = payload
  },
  setConfigRecordTitle(state, title) {
    state.config.preorders.title = { ...state.config.preorders.title, ...title }
  },
  setConfigRecordTypesDisplay(state, payload) {
    state.config.recordTypes.display = payload
  },
  setConfigRecordTitleHelpField(state, helpFields) {
    state.config.preorders.title.helpField = helpFields
  },
  setRecordFormulasDisplay(state, payload) {
    state.config.recordFormulas.display = payload
  },
  setRecordFormulas(state, payload) {
    state.config.recordFormulas.items = payload
  },
  setLoading(state, payload) {
    state.isLoaded = payload
  },
  setPermissions(state, payload) {
    state.permissions = payload
  },
  reset(state) {
    Object.assign(state, defaultState())
  },
}
