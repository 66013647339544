import recordTypes from '@/api/admin/record-types'

export default {
  getRecordTypes: ({ commit }, companySlug = "") => {
    commit('preloader', true)
    return recordTypes.loadRecordTypes(companySlug)
      .then(({ data }) => {
        commit('setRecordTypes', data.data.sort((a, b) => a.sortOrder - b.sortOrder))
      }).finally(() => {
        commit('preloader', false)
      })
  },
  deleteRecordType({ commit }, { slug, params }) {
    commit('preloader', true)
    return recordTypes.deleteRecordTypeEvent(slug, params).then(() => commit('removeRecordType', slug))
      .finally(() => commit('preloader', false))
  },
  updateRecordTypeAction({ commit }, { slug, payload, silent }) {
    return recordTypes.updateRecordType(slug, payload).then((data) => {
      if (!silent) {
        commit('updateRecordType', data.data)
      }
      return data
    }).finally(() => commit('preloader', false))
  },
  changesStatusRecordTypeFeatures({ commit }, payload) {
    commit('preloader', true)
    return recordTypes.changeRecordTypesActive(payload).then(() => {
      commit('user/setConfigRecordTypesDisplay', payload, { root: true })
    }).finally(() => commit('preloader', false))
  },
  createRecordType({ state, commit }, payload) {
    commit('preloader', true)
    const payloadWithPosition = {...payload, sortOrder: state.recordsType.length}
    return recordTypes.createRecordType(payloadWithPosition).then((data) => commit('pushRecordType', data.data))
      .finally(() => commit('preloader', false))
  },
  reorderRecordType({ commit }, payload) {
    commit('setRecordTypes', payload)
    return recordTypes.reorderRecordType({ data: payload.map(({ sortOrder, slug }) => ({ slug, sortOrder })) })
  },
}
