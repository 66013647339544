import { users, user } from '@/api'
import router from '@/router'
import omit from 'lodash.omit'

export default {
  getUsers({ commit }, payload) {
    commit('loading', true)

    return user.getUsers(payload)
      .then(({ data }) => {
        commit('setUsers', data)
      })
      .catch((e) => {
        throw e
      })
      .finally(() => {
        commit('loading', false)
      })
  },

  createUser({ commit }, payload) {
    commit('loading', true)

    return user.createUser(payload)
      .finally(() => {
        commit('loading', true)
      })
  },

  activateUser({ commit }, id) {
    return user.activateUser(id)
      .then(({ data }) => {
        commit('updateOneUserFromMany', data.data)
      })
  },

  deactivateUser({ commit }, id) {
    return user.deactivateUser(id)
      .then(({ data }) => {
        commit('updateOneUserFromMany', data.data)
      })
  },

  getUser({ commit }, payload) {
    commit('loading', true)
    return user.getUser(payload)
      .then(({ data }) => {
        commit('setSingleUser', data.data)
        return data.data
      })
      .finally(() => {
        commit('loading', false)
      })
  },

  changeUserRole({ commit, rootState }, payload) {
    const { userType } = rootState.user.currentUser
    const usersPageType = router.history.current.params.users_type
    const changeRoleMethod = usersPageType === 'customers' ? 'changeRoleCustomerUser' : 'changeRoleInternalUser'

    return users[userType] && users[userType][changeRoleMethod](payload)
      .then(({ data }) => {
        commit('updateOneUserFromMany', data.data)
        return data.data
      })
  },

  editUser({ commit }, payload) {

    commit('loading', true)
    return user.editUser(payload)
      .then(({ data }) => {
        commit('setSingleUser', data.data)
        return data.data
      })
      .finally(() => {
        commit('loading', false)
      })
  },

  removeAccountsRecords({ commit }, payload) {
    commit('loading', true)
    return user.removeAccountsRecords(payload)
      .then(({ data }) => {
        return data.data
      })
      .finally(() => {
        commit('loading', false)
      })
  },

  addAccountsRecords({ commit }, payload) {
    commit('loading', true)
    return user.addAccountsRecords(payload)
      .then(({ data }) => {
        return data.data
      })
      .finally(() => {
        commit('loading', false)
      })
  },

  getSupplierUsers({ commit }, filters) {
    commit('loading', true)
    return users.getUsers({ company: localStorage.getItem('supplier_company'), params: omit(filters, ['id']) })
      .then(({ data }) => {
        const users = data.data.map((user) => {
          return {
            ...user.account,
            ...user.companyAccount,
            id: user.account.id,
            accountId: user.companyAccount.id,
            tags: user.tags,
          }
        })
        const noCollab = users.filter((obj) => !filters.collaborators.map((u) => u.email).includes(obj.email))
        commit('setUsers', data)
        commit('setNoCollaborators', noCollab)
      })
      .finally(() => {
        commit('loading', false)
      })
  },

  getAccountsRecords({ commit }, payload) {
      commit('loading', true)
    return user.getAccountsRecords(payload)
      .then(({ data }) => {
        return data.data
      }).finally(() => {
        commit('loading', false)
      })
  },
  setSearchUsers({ commit }, payload) {
    commit('setSearchUsers', payload)
  },
}
