import store from '@/store'

const tenantAdmin = async ({ to, next, from }) => {
  const isAuth = localStorage.getItem('token')

  if (!isAuth) {
    const company = to?.params?.company
    if (company) {
      return next({ name: 'company-login', params: { company } })
    }
    return next({ name: 'login' })
  }

  if (!store.state.user.currentUser) {
    await store.dispatch('user/getCurrentUserAccount')
  }

  if (store.state.user.currentUser?.administratedTenants?.includes(to.params.tenant)) {
    return next()
  }

  return next(from)
}

export default tenantAdmin
