export const RECORD_PERMISSION = Object.freeze({
  viewCollaborator: 'record_access:view:where_collaborator',
  editCollaborator: 'record_access:edit:where_collaborator',
  viewAll: 'record_access:view:all',
  editAll: 'record_access:edit:all',
})

export const USER_ROLES = Object.freeze([
  { slug: 'admin', name: 'Internal Admin' },
  { slug: 'standard', name: 'Internal Standard' },
  { slug: 'external', name: 'External' },
])

export const USER_ROLE = Object.freeze({
  admin:'Internal Admin',
  standard: 'Internal Standard',
  external: 'External',
})

export const USER_ROLE_TYPE = Object.freeze({
  admin: 'admin',
  standard: 'standard',
  external: 'external',
})

export const USER_TYPE = Object.freeze({
  internal: 'internal',
  external: 'external',
})

export const FINANCIAL_ACCESS = Object.freeze({
  full: 'financial_access:full',
  not: 'financial_access:no_access',
})

export const USER_STATUS = Object.freeze([
    { slug: 'active', name: 'Active'},
    { slug: 'inactive', name: 'Inactive'},
  ])

export const USER_STATUS_TYPE = Object.freeze({
  active: 'active',
  inactive: 'inactive',
})

