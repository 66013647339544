import defaultState from './defaultState'

export default {
  setCustomFields(state, payload) {
    state.customFields = payload
  },
  addCustomField(state, payload) {
    state.customFields.push(payload)
  },
  addSectionToList(state, payload) {
    state.sectionsList.push(payload)
  },
  updateSectionFromList(state, payload) {
    const index = state.sectionsList.findIndex((section) => section.slug === payload.slug)
    const spreadedSectionsList = [...state.sectionsList]
    spreadedSectionsList[index] = { ...spreadedSectionsList[index], ...payload }
    state.sectionsList = spreadedSectionsList
  },
  setSectionList(state, payload) {
    state.sectionsList = payload
  },
  reorderCustomFields(state, payload) {
    payload.forEach((slugWithPosition) => {
      const field = state.customFields.find((field) => field.slug === slugWithPosition.slug)
      if (field) {
        field.position = slugWithPosition.position
      }
    })
  },
  reorderObjectCustomField(state, payload) {
    const object = state.customFields.find((field) => field.slug === payload.slug)
    payload.fields.forEach((field) => {
      const foundedField = object.objectConfiguration
        .find((configurationField) => configurationField.name === field.name)

      if (foundedField) {
        foundedField.position = field.position
      }
    })
  },
  updateCustomField(state, payload) {
    const clonedCustomFields = [...state.customFields]
    const index = state.customFields.findIndex((field) => field.slug === payload.slug)
    if (index !== -1) {
      clonedCustomFields[index] = payload
    }
    state.customFields = clonedCustomFields
  },
  setFunctionsConfigurations(state, payload) {
    state.functionsConfigurations = payload
  },
  reset(state) {
    Object.assign(state, defaultState())
  },
  setEntitiesCondition(state, payload) {
    state.entitiesConditions = payload
  },
  addEntityConditions(state, payload) {
    state.entitiesConditions.push(payload)
  },
  updateEntityConditions(state, payload) {
    const clonedEntitiesConditions = [...state.entitiesConditions]
    const index = state.entitiesConditions.findIndex((item) => item.entitySlug === payload.entitySlug)
    if (index !== -1) {
      clonedEntitiesConditions[index] = payload
    }
    state.entitiesConditions = clonedEntitiesConditions
  },
  deleteEntityConditions(state, id) {
    const index = state.entitiesConditions.findIndex((item) => item.id === id)
    state.entitiesConditions.splice(index, 1)
  },
}
