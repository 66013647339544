import {
  FINANCIAL_ACCESS,
  RECORD_PERMISSION,
  USER_ROLES,
  USER_STATUS,
  USER_TYPE,
} from '@/constants/enums/USER_PERMITIONS'

export default {
  rolesOptions: () => USER_ROLES.map(({ name, slug }) => ({ label: name, value: slug })),
  statusesOptions: () => USER_STATUS.map(({ name, slug }) => ({ label: name, value: slug })),
  userFinancialAccess:
    ({ currentUser }) => currentUser ? currentUser.financialAccess.slug === FINANCIAL_ACCESS.full : false,
  isAdmin: ({ currentUser }) => {
    return currentUser?.type === USER_TYPE.internal && currentUser?.isAdmin
  },
  isStandard: ({ currentUser }) => {
    return currentUser?.type === USER_TYPE.internal
  },
  isViewCollaborator: (store, { recordAccess }) => {
    return recordAccess?.slug === RECORD_PERMISSION.viewCollaborator
  },
  recordAccess: ({ currentUser }) => {
    return currentUser?.recordAccess || false
  },
  editCollaboratorRecords: (store, { recordAccess }) => {
    return recordAccess?.slug === RECORD_PERMISSION.editCollaborator
  },
  editAllRecords: (store, { recordAccess }) => {
    return recordAccess?.slug === RECORD_PERMISSION.editAll
  },
  getObjectChildren: ({ config }) => config?.quoteCustomFieldObjectAggregations?.items || [],
  getFinancialAccess: ({ permissions }) => permissions?.financial_access?.map((item) => ({
    value: item.slug,
    label: item.name,
  })) || [],
  getRecordPermission: ({ permissions }) => permissions?.record_access?.map((item) => ({
    value: item.slug,
    label: item.name,
  })) || [],
  isRecordTypesEnabled: ({ config }) => config?.recordTypes.display || false,
  isESignEnabled: ({ config }) => config?.displayESign.display || false,
  isTaskWidgetEnabled: ({ config }) => config?.taskWidget?.display || false,
  isRecordFormulasEnabled: ({ config }) => config?.recordFormulas?.display || false,
  isOutlookIntegrationEnabled: ({ config }) => {
    return config?.displayOutlookIntegration?.display || false
  },
  isNeedShowOutlookReconnect: ({ currentUser }, { isOutlookIntegrationEnabled }) => {
    return isOutlookIntegrationEnabled
      && currentUser.outlookIntegration.connected
      && !currentUser.outlookIntegration.active
  },
  isAiSummaryEnabled: ({ config }) => config?.displayAISummary?.display || false,
  isVFormsEnabled: ({ config }) => config?.displayVforms?.display || false,
  recordFormulas: ({ config }) => config?.recordFormulas?.items || [],
  userExternalId: ({ currentUser }) => currentUser?.externalId || '',
  recordHelpField: ({ config }) => config?.preorders?.title?.helpField || [],
  recordTitle: ({ config }) => {
    return config
      && config.preorders.title
      && config.preorders.title.custom.singular || 'Record'
  },
  recordsTitle: ({ config }) => {
    return config
      && config.preorders.title
      && config.preorders.title.custom.plural || 'Records'
  },
}
