import httpClient from "@/httpClient"

const getTags = (withMemberCount = false) => {
  const url = `{currentCompanySlug}/tags${withMemberCount ? '?withMemberCount=1' : ''}`
  return httpClient.get(url)
}

const createTag = (payload) => httpClient.post('{currentCompanySlug}/tags', payload)

const attachTag = (payload) => httpClient.post(`{currentCompanySlug}/tags/${payload.tagId}/attach`, payload)

const detachTag = (payload) => httpClient({
  method: 'DELETE',
  url: `{currentCompanySlug}/tags/${payload.tagId}/detach`,
  data: payload,
})

const deleteTag = (tagId, force = false) => httpClient.delete(
  `{currentCompanySlug}/tags/${tagId}${force ? '?force=1' : ''}`,
  {
    headers: {
      notification: 'silent',
    },
  },
)

const renameTag = (payload) => httpClient.put(`{currentCompanySlug}/tags/${payload.tagId}`, payload)

const getTag = (tagId) => httpClient.get(`{currentCompanySlug}/tags/${tagId}`)

export default {
  getTags,
  createTag,
  attachTag,
  detachTag,
  deleteTag,
  renameTag,
  getTag,
}
