import {createStore} from 'vuex'

import LogRocket from "logrocket"
import createPlugin from 'logrocket-vuex'

// Modules
import products from './modules/products'
import layout from './modules/layout'
import admin from './modules/admin'
import auth from './modules/auth'
import user from './modules/user'
import users from './modules/users'
import companies from './modules/companies'
import files from './modules/files'
import records from './modules/records'
import tags from './modules/tags'
import config from './modules/config'
import recordType from './modules/recordType'

const debug = import.meta.env.NODE_ENV !== 'production'

const logrocketPlugin = createPlugin(LogRocket)

const store = createStore({
  modules: {
    layout,
    auth,
    admin,
    user,
    users,
    companies,
    files,
    records,
    tags,
    config,
    products,
    recordType,
  },
  actions: {
    resetAll: {
      root: true,
      handler({ commit }) {
        commit('admin/reset')
        commit('layout/reset')
        commit('user/reset')
        commit('users/reset')
        commit('companies/reset')
        commit('files/reset')
        commit('records/reset')
        commit('tags/reset')
        commit('config/reset')
        commit('products/reset')
        commit('recordType/reset')
      },
    },
    resetForCompanySwitch: {
      root: true,
      handler({ commit}) {
        commit('admin/reset')
        commit('companies/reset')
        commit('layout/reset')
        commit('user/reset')
        commit('users/reset')
        commit('files/reset')
        commit('records/reset')
        commit('tags/reset')
        commit('config/reset')
        commit('products/reset')
        commit('recordType/reset')
      },
    },
  },
  plugins: [logrocketPlugin],
  strict: debug,
})

export default store
