import CUSTOM_FIELDS_TYPES from '@/constants/enums/CUSTOM_FIELDS_TYPES'

export default {
  sectionsAggregation: ({ customFields, sectionsList }) => {
    return [...sectionsList]
      .sort((a, b) => a.position - b.position)
      .map((section) => {
        return {
          section,
          fields: customFields
            .filter(({ sectionSlug }) => sectionSlug === section.slug)
            .map((field) => {
              if ([CUSTOM_FIELDS_TYPES.object, CUSTOM_FIELDS_TYPES.task].includes(field.type)) {
                return {
                  ...field,
                  objectConfiguration: [...field.objectConfiguration].sort((a, b) => a.position - b.position),
                }
              }
              return field
            })
            .sort((a, b) => a.position - b.position),
        }
      })
  },
}
