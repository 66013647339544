import defaultState from './defaultState'

export default {
  setCompanies(state, companies) {
    state.companies = companies
  },
  setCurrentCompany(state, companies) {
    state.currentCompany = companies
  },
  setCurrentCompanyName(state, name) {
    state.currentCompany.name = name
  },
  setProjects(state, projects) {
    state.projects = projects
  },
  setPreOrders(state, preOrders) {
    state.preOrders = preOrders
  },
  reset(state) {
    Object.assign(state, defaultState())
  },
  preloader(state, isLoading) {
    state.isLoading = isLoading
  },
}
