import * as buffer from 'buffer'

const getFieldFromToken = (field) => {
  const token = localStorage.getItem('token')
  if (token) {
    const base64Payload = token.split('.')[1]
    const tokenData = buffer.Buffer.from(base64Payload, 'base64')
    return JSON.parse(tokenData.toString())[field]
  }
  return false
}

export default getFieldFromToken
