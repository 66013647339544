import defaultState from './defaultState'

export default {
  setRecordTypes(state, payload) {
    state.recordsType = payload
  },
  pushRecordType(state, payload) {
    state.recordsType.push(payload.data)
  },
  preloader(state, isLoading) {
    state.isLoading = isLoading
  },
  reset(state) {
    Object.assign(state, defaultState())
  },
  removeRecordType(state, payload) {
    state.recordsType = state.recordsType.filter((item) => item.slug !== payload)
  },
  updateRecordTypeByIndex(state, payload) {
    state.recordsType.splice(payload.index, 1, payload.data)
  },
  updateRecordType(state, { data }) {
    let recordsType = state.recordsType.map((item) => (item.slug === data.slug ? data : item))
    if (data.default) {
      recordsType = recordsType.map((item) => (item.slug !== data.slug ? {
        default: false, name: item.name, id: item.id, slug: item.slug, sections: item.sections,
      } : data))
    }
    state.recordsType = recordsType
  },
}
