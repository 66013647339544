import streamSaver from 'streamsaver'
import { saveAs as fileSaverSaveAs } from 'file-saver'

const checkServiceWorkersRegistration = () => {
  return new Promise((resolve, reject) => {
    if (!'serviceWorker' in navigator) {
      reject()
    }

    navigator.serviceWorker.getRegistration().then((registration) => {
      if (registration) {
        resolve()
      }

      reject()
    }).catch(() => {
      reject()
    })
  })
}

export function saveAs(blob, name) {
  return checkServiceWorkersRegistration().then(() => {
    const fileStream = streamSaver.createWriteStream(name, {
      size: blob.size,
    })
    const readableStream = blob.stream()
    if (window.WritableStream && readableStream.pipeTo) {
      return readableStream.pipeTo(fileStream)
    }
    window.writer = fileStream.getWriter()
    const reader = readableStream.getReader()
    const pump = () => {
      return reader.read()
        .then(res => res.done
          ? writer.close()
          : writer.write(res.value).then(pump))
    }

    return pump()
  }).catch(() => {
    return fileSaverSaveAs(blob, name)
  })
}
