/**
 * Separates the text and adds the ability to view all.
 */
export default {
  beforeMount(el, binding) {
    // keep references to our elements and handlers
    el._viewAll = document.createElement('a')
    el._viewAll.href = '#'
    el._viewAll.text = 'View All'
    el._viewAll.style.display = 'block'

    el._collapse = document.createElement('a')
    el._collapse.href = '#'
    el._collapse.text = 'Collapse'
    el._collapse.style.display = 'block'

    el._viewAllHandler = function(event) {
      event.preventDefault()
      el.textContent = binding.value
      el.append(' ', el._collapse)
    }

    el._collapseHandler = function(event) {
      event.preventDefault()
      el.textContent = `${binding.value.substring(0, binding.arg)}...`
      el.append(' ', el._viewAll)
    }

    if (binding.value?.length > binding.arg) {
      el.textContent = `${binding.value.substring(0, binding.arg)}...`
      el.append(' ', el._viewAll)
      el._viewAll.addEventListener('click', el._viewAllHandler)
    } else {
      el.textContent = binding.value
    }
  },

  beforeUpdate(el) {
    // remove previous listeners
    el._viewAll.removeEventListener('click', el._viewAllHandler)
    el._collapse.removeEventListener('click', el._collapseHandler)
  },

  updated(el, binding) {
    const value = binding.value
    if (value?.length > binding.arg) {
      el.textContent = `${value.substring(0, binding.arg)}...`
      el.append(' ', el._viewAll)
      el._viewAll.addEventListener('click', el._viewAllHandler)
    } else {
      el.textContent = value
    }
  },
}
